<template>
  <div class="routeMonitor">
    <RouteMonitorNav></RouteMonitorNav>
    <div style="height: 100%">
      <router-view />
    </div>
    <information-footer :image-src="imgUrl" image-height="20px" image-width="auto" :email="email" :hrefList="hrefList" />
  </div>
</template>

<script>
import imgUrl from '@/assets/images/Dragonlab_FlowWatch-white.svg'
// import Foot from '@/submodules/components/Footer'
import RouteMonitorNav from '@/components/layout/RouteMonitorNav'
import { getGlobalKV } from '@/api/ConsulApi'
export default {
  name: 'Home',
  components: {
    // Foot,
    RouteMonitorNav,
  },
  created() {
    getGlobalKV('/v1/kv/Global/Email?dc=dc1').then(res => {
      this.email = res.data
    })
    getGlobalKV('/v1/kv/Global/Footer?dc=dc1').then(res => {
      this.hrefList = JSON.parse(String(res.data))
    })
  },
  data() {
    return {
      imgUrl,
      email: 'sec@cgtf.net',
      hrefList: [],
    }
  },
}
</script>

<style lang="scss" scoped>
.routeMonitor {
  background-image: linear-gradient(45deg, #efbdfa, #cbe3fd, #f9d9d9);
  
}
</style>

import request from './request'
import { BASE_URL } from './url_config'

const BSET_URL = `user/`
export function login(user_name, password) {
    const fd = new FormData()
    fd.append('user_name', user_name)
    fd.append('password', password)
    return request({
        method: 'post',
        url: `${BSET_URL}/login`,
        baseURL:BASE_URL,
        data: fd
    })
}


export function register(user_name, password) {
    const fd = new FormData()
    fd.append('user_name', user_name)
    fd.append('password', password)
    return request({
        method: 'post',
        url: `${BSET_URL}/register`,
        baseURL:BASE_URL,
        data: fd
    })
}


export function update(user_id, password,new_password,email) {
    const fd = new FormData()
    fd.append('user_id', user_id)
    fd.append('password', password)
    fd.append('new_password', new_password)
    fd.append('email', email)
    return request({
        method: 'post',
        url: `${BSET_URL}/update/`,
        baseURL:BASE_URL,
        data: fd
    })
}

export function getAllUsers() {
    return request({
        method: 'get',
        url: `${BSET_URL}/users/`,
        baseURL:BASE_URL,
    })
}
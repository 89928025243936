
import {CONSUL_API} from './url_config'
import request from './request'

export function getGlobalKV(kv) {
    return request({
      method: "GET",
      url: `${CONSUL_API}/${kv}`,
      timeout: 3 * 60 * 1000,
    });
  }
  